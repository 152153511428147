
import { Options, Vue } from 'vue-class-component'
import axios from '@/plugins/axios'

interface Result {
  data: any
  message: string
}

interface ResultUpdate {
  message: string
}

interface dataAttribute {
  name: string
  type: string
}
@Options({
  props: {
    idConnect: '',
    connectFrom: '',
    typeMapping: '',
    value: false,
    connectTo: false
  }
})
export default class AttributePage extends Vue {
  name = 'Product Attribute'
  headers = {
    'token-user': localStorage.getItem('tokenUser'),
    account: localStorage.getItem('account')
  }
  listAttributes = []
  listAttributesFrom = []
  listDeletedMappingDefault: any = []
  dataFormMappingAttribute: any = []
  listAttributeRequire = [
    'sku',
    'quantity',
    'title',
    'price',
    'description',
    'cost',
    'weight'
  ]
  dataForm: any = {
    id: '',
    type: '',
    mapping: [],
    mappingShipping: [],
    mappingPayment: []
  }
  dataFormMapping = {
    valueAttributeCustom: '',
    valueAttributeFromCustom: '',
    connect: 'attributeCustom',
    saas: '',
    isCustom: true
  }
  isAttributeFromCustom = false
  isAttributeCustom = true
  newNameAttribute = ''
  idConnect = ''
  connectFrom = ''
  dialogAddAttribute = false

  async created() {
    // if (this.$route.params.id != undefined) {
    //   this.idConnect = this.$route.params.id;
    //   await this.$store.dispatch("auth/setLoadAttributesDone", this.idConnect);
    // } else {
    //   this.idConnect = await localStorage.getItem("load-attribute-done");
    // }
    await this.getList()
    await this.getConnectMapping()
  }

  async getList() {
    let res: Result
    await this.$store.dispatch('setLoading', true, { root: true })
    res = await axios.get('user/product/attribute', {
      headers: this.headers
    })
    if (res) {
      this.listAttributes = res.data.attribute
    }
    await this.$store.dispatch('setLoading', false, { root: true })
  }
  async getConnectMapping() {
    await this.$store.dispatch('setLoading', true, { root: true })
    let res: any = await axios.get('user/connect/attribute-mapping', {
      params: { id: this.idConnect },
      headers: this.headers
    })
    if (res) {
      if (res.name === 'TypeError') {
        return this.$message.warning(res.message)
      } else {
        this.listAttributesFrom = res.attributeFrom
        this.listAttributes = res.attribute
        this.dataForm.mapping = res.mapping
        this.listDeletedMappingDefault = []
        if (
          res.deletedMappingDefault &&
          Array.isArray(res.deletedMappingDefault)
        ) {
          this.listDeletedMappingDefault = res.deletedMappingDefault
        }
        this.getDataFormMappingAttribute()
      }
    }
    await this.$store.dispatch('setLoading', false, { root: true })
  }

  getDataFormMappingAttribute() {
    const mappingAttribute =
      JSON.parse(JSON.stringify(this.dataForm?.mapping || [])) || []
    mappingAttribute.unshift(
      ...[
        {
          connect: 'Sku',
          saas: this.connectFrom == 'netsuite' ? 'sku (itemid)' : 'sku',
          isCustom: false,
          isHiddenButtonRemove: false,
          isDefaultAttribute: true,
          original: 'sku'
        },
        {
          connect: 'Title',
          saas:
            this.connectFrom == 'netsuite' ? 'title (displayname)' : 'title',
          isCustom: false,
          isHiddenButtonRemove: false,
          isDefaultAttribute: true,
          original: 'title'
        },
        {
          connect: 'Description',
          saas: 'description',
          isCustom: false,
          isHiddenButtonRemove: false,
          isDefaultAttribute: true,
          original: 'description'
        },
        {
          connect: 'Cost',
          saas: 'cost',
          isCustom: false,
          isHiddenButtonRemove: false,
          isDefaultAttribute: true,
          original: 'cost'
        },
        {
          connect: 'Weight',
          saas: 'weight',
          isCustom: false,
          isHiddenButtonRemove: false,
          isDefaultAttribute: true,
          original: 'weight'
        },
        {
          connect: 'Images',
          saas: 'images',
          isCustom: false,
          isHiddenButtonRemove: false,
          isDefaultAttribute: true,
          original: 'images'
        }
      ]
    )
    const filteredA1 = mappingAttribute.filter(
      (item1: any) =>
        !this.listDeletedMappingDefault.some(
          (item2: any) =>
            item1.connect === item2.connect && item1.saas === item2.saas
        )
    )
    this.dataFormMappingAttribute = filteredA1
    return filteredA1
  }

  handleAttributeFromInput(value: string) {
    // console.log('handleAttributeFromInput', value)
    this.dataFormMapping.valueAttributeCustom = value
    this.dataFormMapping.connect = 'attributeCustom'
    this.isAttributeCustom = true

    if (this.listAttributesFrom.length === 0) {
      this.dataFormMapping.valueAttributeFromCustom = value
      this.dataFormMapping.saas = 'attributeFromCustom'
    }
  }

  handleUpdate() {
    const listNameAttributes: any[] = []
    this.listAttributes.forEach((attr) => {
      listNameAttributes.push(attr['name'])
    })

    if (listNameAttributes.includes(this.newNameAttribute)) {
      this.$message.warning('Attribute exist !!!')
      return
    }
    let data: dataAttribute = {
      name: this.newNameAttribute,
      type: 'text'
    }
    let listAttributes: any[] = this.listAttributes
    listAttributes.push(data)
    this.update()
  }

  handleDelete(index: number) {
    this.listAttributes.splice(index, 1)
    this.update()
  }

  async update() {
    let res: ResultUpdate = {
      message: ''
    }
    res = await axios.put(
      'user/product/attribute',
      {
        attribute: this.listAttributes
      },
      {
        headers: this.headers
      }
    )
    if (res) {
      this.$message.success(res['message'])
      this.dialogAddAttribute = false
      this.newNameAttribute = ''
    }
  }
  handleSelectAttribute() {
    this.dataFormMapping.valueAttributeCustom = ''
    this.isAttributeCustom = false
    this.dataFormMapping.isCustom = false
    if (this.dataFormMapping.connect === 'attributeCustom') {
      this.isAttributeCustom = true
      this.dataFormMapping.isCustom = true
    }
  }
  handleSelectAttributeFrom() {
    this.dataFormMapping.valueAttributeFromCustom = ''
    this.isAttributeFromCustom = false
    if (this.dataFormMapping.saas === 'attributeFromCustom') {
      this.isAttributeFromCustom = true
    }
    if (this.isAttributeCustom) {
      this.dataFormMapping.valueAttributeCustom = this.dataFormMapping.saas
    }
    if (this.isAttributeFromCustom && this.dataFormMapping.valueAttributeFromCustom == '') {
      this.dataFormMapping.valueAttributeCustom = ''
    }
  }
  // Mapping Attribute
  // Mapping Attribute
  handleAddAttribute() {
    if (this.dataFormMapping.valueAttributeCustom.length) {
      this.dataFormMapping.connect = this.dataFormMapping.valueAttributeCustom
    }
    if (this.dataFormMapping.valueAttributeFromCustom.length) {
      this.dataFormMapping.saas = this.dataFormMapping.valueAttributeFromCustom
    }
    if (
      this.dataFormMapping.connect === '' ||
      this.dataFormMapping.saas === ''
    ) {
      return this.$message.warning(
        'Please do not leave it blank',
        'Error message!!!'
      )
    }
    let item: any
    for (item of this.dataFormMappingAttribute) {
      if (
        item.connect === this.dataFormMapping.connect &&
        item.saas === this.dataFormMapping.saas
      ) {
        return this.$message.warning(
          'This mapping value is exist',
          'Error message!!!'
        )
      }
    }
    this.dataForm.mapping.push({
      connect: this.dataFormMapping['connect'],
      saas: this.dataFormMapping['saas'],
      isCustom: this.dataFormMapping.isCustom
    })
    this.dataFormMappingAttribute.push({
      connect: this.dataFormMapping['connect'],
      saas: this.dataFormMapping['saas'],
      isCustom: this.dataFormMapping.isCustom
    })
    this.dataFormMapping.valueAttributeFromCustom = ''
    this.dataFormMapping.valueAttributeCustom = ''
    this.isAttributeCustom = true
    this.dataFormMapping.connect = 'attributeCustom'
    this.dataFormMapping.saas = ''
  }
  async handleSaveMapping() {
    await this.$store.dispatch('setLoading', true, { root: true })
    let deletedMappingDefault =
      this.listDeletedMappingDefault.filter(
        (item: any) => item.isDefaultAttribute
      ) || []
    var typeUrl = 'mapping'
    this.dataForm.id = this.idConnect
    this.dataForm.deletedMappingDefault = deletedMappingDefault
    await axios
      .post('user/connect/' + typeUrl, this.dataForm, {
        headers: this.headers
      })
      .then(
        (response: any) => {
          return this.$message.success(response.message)
        },
        (response: any) => {
          return this.$message.warning(response.message)
        }
      )
    await this.$store.dispatch('setLoading', false, { root: true })
  }
  handleRemoveOption(index: any, scope: any) {
    this.listDeletedMappingDefault.push(scope.row)
    const indexToRemove = this.dataForm.mapping.findIndex(
      (item: any) =>
        item.connect === scope.row.connect && item.saas === scope.row.saas
    )
    if (indexToRemove !== -1) {
      this.dataForm.mapping.splice(indexToRemove, 1)
    }
    // this.dataForm.mapping.splice(index, 1)
    // this.dataFormMappingAttribute.splice(index, 1);
    this.getDataFormMappingAttribute()
  }
  truncate(string: any) {
    if (string.length > 20) return string.substring(0, 20) + '...'
    else return string
  }
}
